import { MutationTree } from 'vuex';
import { ZetaState } from '@/store/modules/zeta/zetaState';
import ZetaStitchedImageResponse from '@/core/interfaces/ZetaStitchedImageResponse';
import { ZetaImageResponse } from '@/core/interfaces/ZetaImageResponse';

const mutations: MutationTree<ZetaState> = {
    setHubZones: (state, hubZones) => {
        state.hubZones = hubZones;
    },

    setStitchedImageResponse: (state, response: ZetaStitchedImageResponse) => {
        state.stitchedImageResponse = response;
    },

    setImageAreaPreviewResponse: (state, response: ZetaImageResponse) => {
        state.imageAreaPreviewResponse = response;
    },
};

export default mutations;
