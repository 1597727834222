import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store/rootState';
import axios from 'axios';
import { ZetaState } from '@/store/modules/zeta/zetaState';
import Vehicle from '@/core/interfaces/Vehicle';
import { LelyAuth, RelayService, Subscription } from '@tec/frontend-vue-shared';

let imageSubscription: Subscription<any> | null = null;
let relayService: RelayService | null = null;

function createRelay(): RelayService {
    if (!relayService) {
        if (!process.env.VUE_APP_RELAY_URL) {
            throw new Error('VUE_APP_RELAY_URL not provided');
        }

        relayService = new RelayService(process.env.VUE_APP_RELAY_URL, 'machine', 'hmf', () => String(LelyAuth.getAccessToken()));
    }

    return relayService;
}

const actions: ActionTree<ZetaState, RootState> = {
    fetchHubZones: async(context: ActionContext<ZetaState, RootState>) => axios.get(context.getters.zetaApiUrl('/hub-zones'))
        .then(response => context.commit('setHubZones', response.data)),

    subscribeToBarnImage: async(context: ActionContext<ZetaState, RootState>, barnZoneName: string) => {
        if (imageSubscription) {
            await createRelay().unsubscribe(imageSubscription);
        }

        const zetaVehicle = context.rootGetters.vehiclesByTypeName('zeta')[0] as Vehicle;

        imageSubscription = await createRelay().stringSubscribe(zetaVehicle.name, `/ui/${barnZoneName}/stitched_image`);

        imageSubscription.setCallback(response => context.commit('setStitchedImageResponse', JSON.parse(response.data)));
    },

    subscribeToImageArea: async(context: ActionContext<ZetaState, RootState>, areaName: string) => {
        if (imageSubscription) {
            await createRelay().unsubscribe(imageSubscription);
        }

        const zetaVehicle = context.rootGetters.vehiclesByTypeName('zeta')[0] as Vehicle;

        imageSubscription = await createRelay().stringSubscribe(zetaVehicle.name, `/ui/${areaName}/image_preview`);

        imageSubscription.setCallback(response => context.commit('setImageAreaPreviewResponse', JSON.parse(response.data)));
    },
};

export default actions;
