import { Module } from 'vuex';
import actions from '@/store/modules/zeta/actions';
import mutations from '@/store/modules/zeta/mutations';
import { RootState } from '@/store/rootState';
import state from '@/store/modules/zeta/state';
import getters from '@/store/modules/zeta/getters';
import { ZetaState } from '@/store/modules/zeta/zetaState';

const zeta: Module<ZetaState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default zeta;
