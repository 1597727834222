import Fence from '@/core/interfaces/Fence';
import { RootState } from '@/store/rootState';
import Barn from '@/core/interfaces/Barn';
import { GetterTree } from 'vuex';
import FeedType from '@/core/interfaces/FeedType';
import Ration from '@/core/interfaces/Ration';
import Farm from '@/core/interfaces/Farm';
import VehicleType from '@/core/interfaces/VehicleType';
import Vehicle from '@/core/interfaces/Vehicle';
import VehicleAction from '@/core/interfaces/VehicleAction';
import LinkedFence from '@/core/interfaces/LinkedFence';
import PresetRun from '@/core/interfaces/PresetRun';
import VehicleStatus from '@/core/interfaces/VehicleStatus';
import RationVehicleType from '@/core/interfaces/RationVehicleType';
import FenceVisitAction from '@/core/enums/FenceVisitAction';
import PlanningSettings from '@/core/interfaces/PlanningSettings';
import RunsFilterPreferences from '@/core/interfaces/RunsFilterPreferences';

const getters: GetterTree<RootState, RootState> = {
    actionById: (state: RootState) => (actionId: number): VehicleAction => state.actions.find(action => action.id === actionId) as VehicleAction,
    actionByName: (state: RootState) => (name: FenceVisitAction): VehicleAction => state.actions.find(action => action.name === name) as VehicleAction,
    actionsByVehicleTypeId: (state: RootState) => (vehicleTypeId: number): VehicleAction[] => state.vehicleTypeActions[vehicleTypeId] || [],
    fencesSet: (state: RootState): Fence[] => state.fencesList.map((id: number) => state.fences[id])
        .sort((a, b) => a.name.localeCompare(b.name)),
    fencesOrderByBarn: (state: RootState, getters) => getters.fencesSet.sort((a: Fence, b: Fence) => getters
        .barnById(a.barn_id).name.localeCompare(getters.barnById(b.barn_id).name)),
    farm: (state: RootState): Farm | null => (state.farmId ? state.farms[state.farmId] : null),
    farmsSet: (state: RootState): Farm[] => state.farmsList.map((id: number) => state.farms[id])
        .sort((a, b) => a.name.localeCompare(b.name)),
    barnsSet: (state: RootState): Barn[] => state.barnsList.map((id: number) => state.barns[id]),
    barnsWithVehicleType: (state: RootState, getters) => (vehicleTypeId: number): Barn[] => (getters
        .barnsSet as Barn[])
        .filter(barn => (getters.vehiclesByTypeId(vehicleTypeId) as Vehicle[])
            .some(vehicle => vehicle.barn_id === barn.id)),
    feedTypesSet: (state: RootState): FeedType[] => state.feedTypesList
        .map((id: number) => state.feedTypes[id])
        .sort((a, b) => a.name.localeCompare(b.name)),
    fencesByBarn: (state: RootState, getters) => (barnId: number): Fence[] => getters.fencesSet
        .filter((fence: Fence) => fence.barn_id === barnId),
    fenceById: (state: RootState) => (fenceId: number): Fence => state.fences[fenceId],
    fencesWithRationId: (state: RootState, getters) => (rationId: number): Fence[] => getters.fencesSet
        .filter((fence: Fence) => fence?.ration_id === rationId),
    rations: (state: RootState): Ration[] => state.rations
        .sort((a, b) => a.name.localeCompare(b.name)),
    rationById: (state: RootState) => (rationId: number): Ration | null => state.rations
        .find(r => r.id === rationId) || null,
    rationVehicleTypeById: (state: RootState) => (rationVehicleTypeId: number): RationVehicleType | null => state.rations
        .flatMap(r => r.ration_vehicle_types)
        .find(rvt => rvt.id === rationVehicleTypeId) || null,
    rationByRationVehicleType: (state: RootState) => (rationVehicleTypeId: number): Ration | null => state.rations
        .find(ration => ration.ration_vehicle_types
            .some(rationVehicleType => rationVehicleType.id === rationVehicleTypeId)) || null,
    rationsByVehicleTypeId: (state: RootState, getters) => (vehicleTypeId: number): Ration[] => getters
        .rations
        .filter((ration: Ration) => ration.ration_vehicle_types.some(rft => rft.vehicle_type_id === vehicleTypeId)),
    barnById: (state: RootState) => (barnId: number): Barn => state.barns[barnId],
    feedTypeById: (state: RootState) => (feedTypeId: number): FeedType => state.feedTypes[feedTypeId],
    feedTypesByVehicleId: (state: RootState, getters) => (vehicleTypeId: number): FeedType[] => getters.feedTypesSet
        .filter((feedType: FeedType) => feedType.vehicle_type_id === vehicleTypeId),
    feedTypesByVehicleTypeName: (state: RootState, getters) => (vehicleTypeName: string): FeedType[] => getters
        .feedTypesByVehicleId(getters.vehicleTypeByName(vehicleTypeName)?.id),
    vehiclesSet: (state: RootState): Vehicle[] => state.vehiclesList
        .map((id: number) => state.vehicles[id])
        .sort((a, b) => a.name.localeCompare(b.name)),
    vehicleById: (state: RootState) => (vehicleId: number): Vehicle => state.vehicles[vehicleId],
    vehicleTypesSet: (state: RootState): VehicleType[] => state.vehicleTypesList
        .map((id: number) => state.vehicleTypes[id])
        .sort((a, b) => a.name.localeCompare(b.name)),
    vehicleTypesWithActions: (state: RootState, getters): VehicleType[] => getters.vehicleTypesSet
        .filter((vehicleType: VehicleType) => vehicleType.name !== 'zeta'),
    vehicleTypesWithVehicles: (state: RootState, getters: any): VehicleType[] => getters.vehicleTypesSet
        .filter((vehicleType: VehicleType) => getters.vehiclesByTypeName(vehicleType.name).length > 0),
    vehicleTypeById: (state: RootState) => (vehicleTypeId: number): VehicleType => state.vehicleTypes[vehicleTypeId],
    vehicleTypeByRationVehicleTypeId: (state: RootState, getters) => (rationVehicleTypeId: number): VehicleType | null => getters
        .vehicleTypeById(state.rations
            .flatMap(ration => ration.ration_vehicle_types)
            .find(rationVehicleType => rationVehicleType.id === rationVehicleTypeId)
            ?.vehicle_type_id) || null,
    vehicleTypeByName: (state: RootState, getters: any) => (vehicleTypeName: string): VehicleType => getters.vehicleTypesSet
        .find((vehicleType: VehicleType) => vehicleType.name === vehicleTypeName),
    vehiclesByTypeName: (state: RootState, getters: any) => (vehicleTypeName: string): Vehicle[] => getters.vehiclesSet
        .filter((vehicle: Vehicle) => vehicle.vehicle_type_id === getters.vehicleTypeByName(vehicleTypeName)?.id),
    vehiclesByTypeNameAndBarnId: (state: RootState, getters: any) => (vehicleTypeName: string, barnId: number): Vehicle[] => (getters
        .vehiclesByTypeName(vehicleTypeName) as Vehicle[])
        .filter(vehicle => vehicle.barn_id === barnId),
    vehiclesByTypeId: (state: RootState, getters: any) => (vehicleTypeId: number): Vehicle[] => getters.vehiclesSet
        .filter((vehicle: Vehicle) => vehicle.vehicle_type_id === vehicleTypeId),
    isLoading: (state: RootState) => state.loadingRefCount > 0 || state.planningInProgress,
    linkedFences: (state: RootState): LinkedFence[] => state.linkedFences,
    linkedFenceByFenceId: (state: RootState) => (fenceId: number): LinkedFence | null => state.linkedFences
        .find(linkedFence => linkedFence.fence_ids.includes(fenceId)) || null,
    linkedFencesFencesByFenceId: (state: RootState, getters) => (fenceId: number): Fence[] => state.linkedFences
        .filter(linkedFence => linkedFence.fence_ids.includes(fenceId))
        .flatMap(linkedFence => linkedFence.fence_ids)
        .filter(linkedFenceFenceId => linkedFenceFenceId !== fenceId)
        .map(linkedFenceFenceId => getters.fenceById(linkedFenceFenceId)),
    planningSettings: (state: RootState): PlanningSettings => state.planningSettings as PlanningSettings,
    presetRuns: (state: RootState) => state.presetRuns
        .sort((a, b) => Number(a.dispense_time.replace(':', ''))
            - Number(b.dispense_time.replace(':', ''))),
    presetRunById: (state: RootState) => (id: number): PresetRun | null => state.presetRuns
        .find(p => p.id === id) || null,
    presetRunsWithRationId: (state: RootState, getters) => (rationId: number): PresetRun[] | null => {
        const ration: Ration = getters.rationById(rationId);
        const rationVehicleTypeIds = ration.ration_vehicle_types.map(rvt => rvt.id);

        return (getters.presetRuns as PresetRun[]).filter(presetRun => rationVehicleTypeIds.includes(presetRun.ration_vehicle_type_id));
    },
    vehicleStatusses: (state: RootState, getters): VehicleStatus[] => state.vehicleStatusses
        .filter(s => getters.vehicleById(s.id)),
    vehicleStatusByVehicleId: (state: RootState, getters) => (vehicleId: number): VehicleStatus | null => ((getters.vehicleStatusses as VehicleStatus[])
        .find(vehicleStatus => vehicleStatus.id === vehicleId)) || null,
    vehicleStatussesWithLocation: (state: RootState, getters): VehicleStatus[] => (getters.vehicleStatusses as VehicleStatus[])
        .filter(s => s.lat && s.lng),
    unavailabilities: (state: RootState) => state.unavailabilities,
    unavailabilitiesByFenceId: (state: RootState) => (fenceId: number) => state.unavailabilities
        .filter(unavailability => unavailability.fences.includes(fenceId)),
    unavailabilitiesByWeekday: (state: RootState) => (weekday: number) => state.unavailabilities
        .filter(unavailability => unavailability.weekdays.includes(weekday)),
    runsFilterPreferences: (state: RootState): RunsFilterPreferences => state.runsFilterPreferences,
};
export default getters;
