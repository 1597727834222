import { RootState } from '@/store/rootState';
import { GetterTree } from 'vuex';
import { ZetaState } from '@/store/modules/zeta/zetaState';
import { ZetaImageArea } from '@/core/interfaces/ZetaImageArea';

const getters: GetterTree<ZetaState, RootState> = {
    zetaApiUrl: (state: ZetaState, getters: any, rootState: RootState) => (url: string) => `/zeta/farms/${rootState.farmId}${url}`,

    barnZones: (state: ZetaState) => state.hubZones
        .flatMap(hubZone => hubZone.barn_zones)
        .sort((a, b) => {
            if (a.calving && !b.calving) return -1;

            if (!a.calving && b.calving) return 1;

            return a.name.localeCompare(b.name);
        }),

    barnZoneById: (state: ZetaState) => (id: number) => state.hubZones
        .flatMap(hubZone => hubZone.barn_zones)
        .find(barnZone => barnZone.id === id),

    barnZoneByName: (state: ZetaState) => (name: string) => state.hubZones
        .flatMap(hubZone => hubZone.barn_zones)
        .find(barnZone => barnZone.name === name),

    imageAreasByBarnZone: (state: ZetaState, getters: any) => (barnZoneId: number) => (getters.barnZoneById(barnZoneId).image_areas as ZetaImageArea[])
        .sort((a, b) => a.name.localeCompare(b.name)),

    stitchedImageResponse: (state: ZetaState) => state.stitchedImageResponse,

    imageAreaPreviewResponse: (state: ZetaState) => state.imageAreaPreviewResponse,
};
export default getters;
